<template>
  <div class="flex lg:flex-col items-center justify-center lg:h-screen px-6 lg:px-0">
    <div class="w-full md:w-8/12 lg:w-5/12 pt-32 lg:pt-0 lg:-mt-24">
      <ValidationObserver ref="requestResetPasswordForm" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(requestResetPassword)" method="post">
          <div class="text-center mb-10">
            <h2 class="font-bold text-3xl">{{ emailSent ? 'Check your email' :'Reset password'}}</h2>
          </div>
          <div class="grid grid-cols-1 row-gap-6" v-if="!emailSent">
            <div>
              <ValidationProvider rules="required|email" vid="email" name="Email" v-slot="{ errors }">
                <label class="form-lable">Email</label>
                <input
                  v-model="form.email"
                  class="form-input w-full"
                  :class="{'border-red-500': errors.length}"
                  type="email"
                  placeholder="Email"
                >
                <p class="text-red-500 text-sm mt-1" v-if="errors.length">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
          </div>

          <button class="btn bg-gray-600 my-10 flex justify-center w-full" type="submit" v-if="!emailSent">
            <span v-if="loading">
              <loading-icon class="h-5 w-5 mr-4"/>
            </span>
            <span v-else>Send password reset link</span>
          </button>
          <div class="grid grid-cols-1 row-gap-6" v-else>
            <p class="font-bold">
              We've sent an email to {{form.email}}. Click the link in the email to reset your password.
            </p>
            <p class="my-10">
              If you don't see the email , check other places it might be, like your junk, spam, social or other
              folders.
            </p>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
  import { setCSRFCookie } from '@/api/auth';
  
  export default {
    name: "ForgotPassword",
    data() {
      return {
        loading: false,
        emailSent: '',
        form: {
          email: null
        }
      }
    },
    methods: {
      async requestResetPassword() {
        this.loading = true;
        await setCSRFCookie()
        this.$store.dispatch('auth/requestResetPassword', this.form)
          .finally(() => {
            this.emailSent = this.form.email;
            this.loading = false;
            this.form.email = null
          })
      }
    }
  }
</script>
